import React, { useState } from "react";

import "./Form.scss";

const Form = ({ en = false }) => {
  const [name, setName] = useState("");

  const placeholder = en
    ? "Name - dietary restrictions"
    : "Ime in priimek - prehranske posebnosti";

  const button = en ? "I'll be there" : "Potrjujem prihod";

  return (
    <form
      method="post"
      action="https://getform.io/f/582c37c7-bc74-4721-830e-7aeb7ed88f9e"
    >
      <textarea
        id="names"
        name="names"
        placeholder={placeholder}
        value={name}
        rows="5"
        onChange={(e) => setName(e.target.value)}
      />
      {name && <input className="confirm" type="submit" value={button} />}
    </form>
  );
};
export default Form;
