import React from "react";
import "normalize.css";
import "./index.scss";
import Header from "../components/Header/Header";
import GoldCircle from "../components/GoldCircle/GoldCircle";
import Form from "../components/Form/Form";

const En = () => {
  return (
    <>
      <Header />
      <main>
        <div className="main-wrap">
          <GoldCircle width="350px" borderWidth="10px">
            <div className="col">
              <div className="name">Sandi Bitenc</div>
              <div className="and">&</div>
              <div className="name">Petra Kmetec</div>
            </div>
          </GoldCircle>
          <h1>Wedding invitation</h1>
          <div className="yt">
            <iframe
              src="https://www.youtube.com/embed/G1XlUkCKJ4M?controls=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              rel="0"
              listType="playlist"
              enablejsapi="1"
              modestbranding="1"
              showinfo="0"
            ></iframe>
          </div>
          <div className="and dateand">SATURDAY 3:30pm</div>
          <div className="date">
            <div className="date1">9</div>
            <div className="date2">JULY</div>
            <div className="date3">2022</div>
          </div>
          <h3>Winehouse Dopler</h3>

          <a
            href="https://goo.gl/maps/xYcdZLmSyyLSZ6Dp9"
            target="_blank"
            rel="noreferrer"
          >
            Kozjak nad Pesnico 79, 2201 Zgornja Kungota, Slovenia
          </a>

          <p>
            9th of July is a very special day for us. Seven years ago, exactly
            on this date, we have started our journey together and now we want
            to upgrade it. With your company of course.
          </p>
          <p>
            With you we would like to share our happiness and the continuation
            of a magical story that we are writing together for so many years.
          </p>
          <p>
            We invite you to our wedding on the 9th of July 2022 at the
            beautiful venue of the winehouse Dopler.
          </p>
          <p>
            We kindly ask you to confirm your attendance till latest 10th of May
            2022 by writing in your names and possible dietary restrictions and
            clicking the button <i>I&apos;ll be there</i>.
          </p>
          <Form en={true} />
        </div>
      </main>
    </>
  );
};

export default En;
