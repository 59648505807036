import React from "react";

import "./GoldCircle.scss";

const GoldCircle = ({ children, borderWidth, width }) => {
  return (
    <div
      className="gold-border"
      style={{
        padding: borderWidth,
        width: width,
        minWidth: width,
        height: width,
      }}
    >
      <div className="gold-inn">{children}</div>
    </div>
  );
};
export default GoldCircle;
