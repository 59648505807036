import React from "react";
import GoldCircle from "../GoldCircle/GoldCircle";

import "./Header.scss";

const Header = () => {
  return (
    <header>
      <GoldCircle width="1100px" borderWidth="5px">
        <GoldCircle width="1030px" borderWidth="15px"></GoldCircle>
      </GoldCircle>
    </header>
  );
};
export default Header;
